#team {
  padding: 10px 0;
  background: linear-gradient(to right, #a3cae1 0%, #4b95e0 100%);
  color: rgba(36, 164, 94, 0.75);
}
#team h4 {
  margin: 10px 0;
  color: red;
  display: inline-block;
  text-decoration: underline;
  font-size: 25px;
  padding: 2px;
               

}
#team h5 {
  
  color: black;
  
  font-size: 14;
  margin: 0px 0px 10px;
  height: 50px;
  
               

}
h2::after {
  content: " 👋"; /* Adds a waving hand emoji after the heading text */
}

.btn-toggle {
  background: white;
  border: black;
  color: rgb(38, 101, 160);
  cursor: pointer;
  text-decoration: none;
}
#team p {
  color: black;
  font-family: 'Comic Neue';
  font-weight: bold;
  font-size: 18;
  
}
#team .team-img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 3px solid black;
  padding: 0px;

}
#team .thumbnail {
  background: linear-gradient(to right, #f4f4f4 0%, #fafafb 100%);

  border-radius: 10%;
  border: 8px double black;
  padding:25px;
}
#team .thumbnail .caption {
  padding: 20px 2px 0;
  color: black;
  overflow: hidden;
}

#team h2 {
  color: black;
  margin-top: 10px;
  margin-bottom: 0px;
  padding-bottom: 8px;
}
/* Responsive Media Queries for Team Section */

/* Extra Small Devices (up to 375px) */
@media screen and (max-width: 375px) {
  #team {
    padding: 50px 0;
  }

  #team .team-img {
    width: 180px;
    height: 180px;
  }

  #team h2 {
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  #team h4 {
    font-size: 16px;
    margin: 3px 0;
  }

  #team p {
    font-size: 14px;
  }

  .btn-toggle {
    font-size: 14px;
  }

  #team .thumbnail .caption {
    padding: 5px 0 0;
  }
}

/* Small Devices (376px - 576px) */
@media screen and (min-width: 376px) and (max-width: 576px) {
  #team {
    padding: 75px 0;
  }

  #team .team-img {
    width: 200px;
    height: 200px;
  }

  #team h2 {
    font-size: 28px;
    margin-top: 8px;
    margin-bottom: 12px;
    padding-bottom: 12px;
  }

  #team h4 {
    font-size: 18px;
  }

  #team p {
    font-size: 16px;
  }

  .btn-toggle {
    font-size: 15px;
  }
}

/* Medium Devices (577px - 768px) */
@media screen and (min-width: 577px) and (max-width: 768px) {
  #team {
    padding: 80px 0;
  }

  #team .team-img {
    width: 20px;
    height: 20px;
  }

  #team h2 {
    font-size: 32px;
  }

  #team h4 {
    font-size: 20px;
  }

  #team p {
    font-size: 18px;
  }
}

/* Large Devices (769px - 992px) */
@media screen and (min-width: 769px) and (max-width: 992px) {
  #team {
    padding: 90px 0;
  }

  #team .team-img {
    width: 20px;
    height: 20px;
  }
}

/* Landscape Orientation */
@media screen and (max-height: 500px) and (orientation: landscape) {
  #team {
    padding: 50px 0;
  }

  #team .team-img {
    width: 160px;
    height: 160px;
  }

  #team h2 {
    font-size: 24px;
  }

  #team h4 {
    font-size: 16px;
  }

  #team p {
    font-size: 14px;
  }
}

/* High Resolution Displays */
@media screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min-resolution: 192dpi) {
  #team .team-img {
    /* Consider using a higher resolution image */
    background-size: cover;
  }
}

/* Print Styles */
@media print {
  #team {
    background: none;
    color: #000;
    padding: 20px 0;
  }

  #team .team-img {
    width: 200px;
    height: 200px;
    border: 1px solid #000;
  }

  #team h2,
  #team h4,
  #team p {
    color: #000;
  }

  .btn-toggle {
    display: none;
  }
}

/* Accessibility: Reduce motion for users who prefer reduced motion */
@media (prefers-reduced-motion: reduce) {
  .team-img {
    transition: none;
  }
}
