.feature-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 600px;
  height: auto;
  margin: auto;
  overflow: hidden;
  margin-bottom: 5%;
  aspect-ratio: 5/9; /* Maintains a consistent aspect ratio */
  background-size: contain; /* Ensures the entire image fits within the container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents repeating the image */
  background-image: url("../../public/img/features.jpg"); /* Path to your image */
}
.col-md-3{
  margin-right: -20px;
  margin-left: 5px;
}
#features {
  background: #e5e5e5;
  padding: 100px 0;
}

row {
  overflow: hidden;
}

#features i.fa {
  font-size: 75px;
  margin-bottom: 20px;
  transition: all 0.5s;
  color: #fff;
  width: 135px;
  height: 135px;
  padding: 30px 0;
  border-radius: 50%;
  background: linear-gradient(to right, #6372ff 0%, #18284a 100%);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}

.features-heading {
  color: #18284a;
}

.features-description {
  font-size: 25px;
  color: #000000;
}

.features-content {
  margin: 5vw;
  display: flex;
  align-items: center;
  font-family: Anton;
}

/* Responsive Media Queries for Features Section */

/* Extra Small Devices (up to 375px) */
@media screen and (max-width: 375px) {
  .feature-image-container {
    max-width: 100%;
    aspect-ratio: 3/4; /* Slightly adjusted for smaller screens */
    margin-bottom: 10%;
  }

  #features {
    padding: 50px 0;
  }

  #features i.fa {
    font-size: 50px;
    width: 100px;
    height: 100px;
    padding: 20px 0;
  }

  .features-description {
    font-size: 18px;
  }

  .features-content {
    flex-direction: column;
    margin: 3vw;
    text-align: center;
  }
}

/* Small Devices (376px - 576px) */
@media screen and (min-width: 376px) and (max-width: 576px) {
  .feature-image-container {
    max-width: 90%;
    aspect-ratio: 4/5;
    margin-bottom: 8%;
  }

  #features {
    padding: 75px 0;
  }

  #features i.fa {
    font-size: 60px;
    width: 110px;
    height: 110px;
    padding: 25px 0;
  }

  .features-description {
    font-size: 20px;
  }

  .features-content {
    flex-direction: column;
    margin: 4vw;
    text-align: center;
  }
}

/* Medium Devices (577px - 768px) */
@media screen and (min-width: 577px) and (max-width: 768px) {
  .feature-image-container {
    max-width: 0%;
    aspect-ratio: 16/9;
    margin-bottom: 6%;
  }

  #features {
    padding: 80px 0;
  }

  #features i.fa {
    font-size: 65px;
    width: 120px;
    height: 120px;
    padding: 25px 0;
  }

  .features-description {
    font-size: 22px;
  }

  .features-content {
    flex-direction: column;
    margin: 5vw;
    align-items: center;
  }
}

/* Large Devices (769px - 992px) */
@media screen and (min-width: 769px) and (max-width: 992px) {
  .feature-image-container {
    max-width: 70%;
    aspect-ratio: 5/6;
  }

  #features {
    padding: 90px 0;
  }

  #features i.fa {
    font-size: 70px;
    width: 125px;
    height: 125px;
  }

  .features-description {
    font-size: 23px;
  }
}

/* Landscape Orientation */
@media screen and (max-height: 500px) and (orientation: landscape) {
  .features-content {
    flex-direction: row;
    align-items: center;
  }

  .feature-image-container {
    max-width: 40%;
    margin-right: 5%;
  }
}

/* Print Styles */
@media print {
  .feature-image-container {
    max-width: 100%;
    aspect-ratio: auto;
  }

  #features i.fa {
    display: none;
  }
}

/* High Resolution Displays */
@media screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min-resolution: 192dpi) {
  .feature-image-container {
    background-image: url("../../public/img/features.jpg"); /* Provide a higher resolution image */
  }
}
