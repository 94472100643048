.btn-enroll-now {
  position: fixed;
  bottom: 40px;
  right: 50px;
  animation: pulse 1.75s infinite;
  background-attachment: fixed;
  background-color:rgb(221, 153, 153);
  color: blue;
  border: black;
  border-radius: 10px;
  size: 200px;
  border-color: black;

}

.close-button{
  font-size: 25px;
  color:rgba(4, 4, 4, 0.694);
  margin-top: -80px;
  margin-right: 160px;
  margin-left: -230px;
  margin-bottom: 450px;


  
}

.btn-haha-now {
  position: fixed;
  bottom: 100px;
  right: 15px;
  animation: pulse 1.75s infinite;
  background-attachment: fixed;
  background-color:rgb(221, 153, 153);
  color: black;
  border: black;
  border-radius: 10px;
  size: 200px;
  border-color: black;

}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 400px) {
  #features,
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
    padding: 120px 0;
  }

  #portfolio {
    width: 110%;
  }
}
/* Full-screen overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Covers the full viewport height */
  background-color: rgba(157, 153, 153, 0.8); /* Semi-transparent black background */
  z-index: 999; /* Ensure it's above other elements */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Overlay content */
.overlay-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%; /* Allow some margin on smaller screens */
  max-width: 800px; /* Limit the maximum width of the content */
}

/* Responsive image styling */
.overlay-image {
  width: 100%; /* Scale image to fit container */
  height: auto; /* Maintain aspect ratio */
  max-height: 40%; /* Ensure it doesn't overflow vertically */
  object-fit: contain; /* Maintain aspect ratio and fit within container */
  border-radius: 10px; /* Optional: Adds rounded corners */
  margin-top: 50px;
}

/* Close button */
.close-btn {
  position: fixed;
  top: 70px; /* Adjust to place below the navbar */
  right: 20px;
  font-size: 5rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1001;
  transition: transform 0.2s ease;
}

.close-btn:hover {
  transform: scale(1.2);
}

/* Ensure the overlay covers all screen heights in mobile browsers */
@supports (-webkit-touch-callout: none) {
  .overlay {
    height: -webkit-fill-available; /* Fix for mobile browsers */
  }
}

/* Adjust for responsiveness */
@media (max-width: 768px) {
  .overlay-content {
    max-width: 95%; /* Allow more width for smaller screens */
  }

  .close-btn {
    font-size: 3rem;
    top: 50px;
    right: 15px;
  }
}
