/* Intro Section Styling */
header .intro-text {
  padding-top: 10rem !important;
  padding-bottom: 100px !important;
  padding-left: 00px;
  text-align: center;
}

.intro-photo {
  margin: 145px 0 0 0;
}

.intro {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* padding: 0 5%; */
  background-color: #e6e6e6;
  background-size: cover;
  /* background: url("../../public/img/header/header_2.jpg"); */
}

/* Header Content Styling */
.header-content {
  border: 4px solid black;
  border-radius: 10%;
  padding: 10%;
  background: rgba(
    0,
    0,
    0,
    0.256
  ); /* Added a semi-transparent background for better contrast */
}

.btn-demo {
  font-size: large;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: #054349;
  border: 20px;
  border-radius: 50%;
  background-color: pink;
  margin-bottom: -40px;
  margin-top: -10px;
}


/* Headings Styling */
.intro h1 {
  font-family: "Raleway", sans-serif;
  color: #fff;
  font-size: 82px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
}

.intro h1 span {
  font-weight: 800;
  color: #5ca9fb;
}

.intro p {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  margin: 0 0;
  margin-bottom: 60px;
}

.heading-3 {
  color: #054349 !important;
  font-size: 20px;
}

/* Slider Container */
.slider-container {
  padding-top: -5rem;
  padding-bottom: 10rem;
  height:800px;
  
  
  max-width: 480px;
  
  border-radius: 10px;
}

/* Slider Image */
.slider-image {
  display: block;
  margin:-50px 100px 150px 100px;
  width: 100%;
  border: 5px black solid;
  border-radius: 10%;
  
  object-fit: contain;
  transition: transform 0.5s ease-in-out;
}

/* Navigation Buttons */
.slider-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 2;
  font-size: 18px;
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

.slider-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Responsive Design */
/* Responsive Media Queries */

/* Extra Small Devices (mobile phones, 320px and up) */
@media screen and (max-width: 375px) {
  header .intro-text {
    padding-top: 100px !important;
    padding-bottom: 30px !important;
  }

  .intro h1 {
    font-size: 28px;
  }

  .intro p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  .slider-container {
    width: 100%;
    margin: 0 auto;
  }

  .header-content {
    padding: 5%;
    border-radius: 5%;
  }

  .slider-btn {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}

/* Small Devices (landscape phones, 376px and up) */
@media screen and (min-width: 376px) and (max-width: 576px) {
  header .intro-text {
    padding-top: 120px !important;
    padding-bottom: 50px !important;
  }

  .intro h1 {
    font-size: 32px;
  }

  .intro p {
    font-size: 15px;
    line-height: 26px;
    margin-bottom: 40px;
  }

  .header-content {
    padding: 7%;
  }
}

/* Medium Devices (tablets, 577px and up) */
@media screen and (min-width: 577px) and (max-width: 768px) {
  header .intro-text {
    padding-top: 180px !important;
    padding-bottom: 100px !important;
  }

  .intro {
    flex-direction: column;
    padding: 0 3%;
  }

  .intro h1 {
    font-size: 42px;
  }

  .intro p {
    font-size: 17px;
    line-height: 28px;
  }

  .slider-container {
    width: 80%;
  }

  .header-content {
    padding: 8%;
  }
}

/* Large Devices (desktops, 769px and up) */
@media screen and (min-width: 769px) and (max-width: 992px) {
  header .intro-text {
    padding-top: 12rem !important;
    padding-bottom: 180px !important;
  }

  .intro h1 {
    font-size: 68px;
  }

  .intro p {
    font-size: 20px;
  }
}

/* Landscape Orientation */
@media screen and (max-width: 992px) and (orientation: landscape) {
  .intro {
    flex-direction: row;
    align-items: center;
  }

  header .intro-text {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
}

/* High Resolution Displays */
@media screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min-resolution: 192dpi) {
  .slider-image {
    image-rendering: -webkit-optimize-contrast;
  }
}

/* Print Styles */
@media print {
  .intro {
    display: block;
    background: none;
  }

  .slider-btn {
    display: none;
  }
}

/* Hover and Interaction Modifications */
@media (hover: hover) {
  .slider-btn:hover {
    background-color: rgba(0, 0, 0, 0.7);
    transform: translateY(-50%) scale(1.1);
  }
}

/* Body Scrollbar Fix */
body {
  overflow-x: hidden;
}

/* Button Styling */
a.btn {
  background-color: #5ca9fb;
  color: white;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

a.btn:hover {
  background-color: #3b87e0;
}